<template>
  <div class="mb-2">
    <div horizontal :label="`${title}${mandatory ? '*' : ''}`" :message="helptext" class="field my-1">
      <div class="is-flex">
        <div v-if="type==='boolean'" @click="toggleBoolean()" class="is-clickable is-size-5 mr-1" style="padding-top: 1px;">
          <i v-if="localValue" class="fas fa-check-square has-text-success"></i>
          <i v-else class="far fa-square" style="color: #afafaf;"></i>
        </div>
        <div v-if="!hideLabel" class="field-label is-normal pt-1">
          <label @click="type==='boolean' && toggleBoolean()" class="label is-size-6" style="text-align: left !important;">{{ `${title}${mandatory ? '*' : ''}` }}</label>
        </div>
      </div>
      <span v-if="helptext || videoTutorialYoutubeId" class="help is-size-7 has-text-grey mt-0 mb-1" :class="{'is-clickable': videoTutorialYoutubeId}" @click="videoTutorialYoutubeId ? toggleVideoTutorialModal() : null">
        <i v-if="videoTutorialYoutubeId" class="fas fa-video"></i>
        {{ videoTutorialYoutubeId && helptext ? `Click here for video tutorial | ${helptext}` : helptext ? helptext : 'Click here for video tutorial'}}
      </span>
      <div v-if="errorMessage" class="tag is-danger p-2 error-message-modifier mb-1">{{ errorMessage }}</div>
      <div v-else-if="inputErrorMessage" class="tag is-danger p-2 error-message-modifier mb-1">{{ inputErrorMessage }}</div>
      <div class="field-body">
        <div class="field mr-0" :class="{'is-flex': unit}">
          <div class="control">
            <input
              v-if="['text'].includes(type)"
              class="input"
              v-bind:class="{'is-small': size === 'small', 'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
              :placeholder="placeholder"
              :type="type"
              v-model="localValue"
              v-on:blur="checkForErrors()"
            />
            <input
              v-if="type==='number'"
              class="input has-short-input-max-width my-1"
              v-bind:class="{'is-small has-short-input-max-width': size === 'small', 'is-medium is-fullwidth has-text-centered': size === 'medium', 'is-large is-fullwidth has-text-centered': size === 'large', 'is-danger': errorMessage}"
              :placeholder="placeholder"
              type="text"
              v-model="localValue"
              v-num
              v-on:blur="checkForErrors()"
            />
            <input
              v-if="type==='integer'"
              class="input has-short-input-max-width my-1"
              v-bind:class="{'is-small has-short-input-max-width': size === 'small', 'is-medium is-fullwidth has-text-centered': size === 'medium', 'is-large is-fullwidth has-text-centered': size === 'large', 'is-danger': errorMessage}"
              :placeholder="placeholder"
              :type="'number'"
              v-model="localValue"
              v-int
              v-on:blur="checkForErrors()"
            />
            <div v-if="type==='image-url' && localValue && localValue.length > 0 && localValue.includes('http')">
              <img class="files-image mb-1" :src="localValue">
            </div>
            <div v-if="type==='mp3-url' && localValue && localValue.length > 0">
              <audio controls="controls">
                <source :src="localValue" type="audio/mpeg">
              </audio>
            </div>
            <input
              v-if="['image-url', 'mp3-url'].includes(type)"
              class="input mb-1"
              v-bind:class="{'is-small': size === 'small', 'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
              :placeholder="placeholder"
              :type="type"
              v-model="localValue"
              v-on:blur="checkForErrors()"
            />
            <div
            v-if="['image-url', 'mp3-url'].includes(type)"
            @click="toggleFileSelector"
            class="button is-small"
            >
              Select from files
            </div>
            <div
            v-if="['image-url', 'mp3-url'].includes(type)"
            @click="toggleUnsplashView"
            class="button is-small ml-1"
            >
              Search the Internet
            </div>
            <div
            v-if="['image-url', 'mp3-url'].includes(type)"
            @click="clearText"
            class="button is-grey is-outlined is-small ml-1"
            >
              Clear
            </div>
            <div v-if="type==='markdown'" class="">
              <nav class="level is-mobile is-align-items-flex-start mb-1">
                <div class="level-left markdown-level-modifier">
                  <editor
                    style="width: 100%;"
                    height="200px"
                    ref="markdownEditor"
                    :initialValue="localValue"
                    :options="markdownEditorOptions"
                    @change="onEditorChange"
                    previewStyle="tab"
                    :key="markdownEditorRefresher"
                  />
                </div>
                <div class="level-right markdown-level-modifier markdown-preview-border">
                  <div class="px-1 is-flex-grow-1">
                    <markdown-output :key="markdownEditorRefresher+1" :markdown="localValue" />
                  </div>
                </div>
              </nav>
              <div class="buttons mb-0">
                <div @click="toggleTextGenView" class="grey-rounded-small-button">
                  generate/improve text using AI
                </div>
                <div @click="toggleFileSelector" class="grey-rounded-small-button">
                  add image/video/mp3 from Files
                </div>
                <div @click="toggleUnsplashView" class="grey-rounded-small-button">
                  add image from the Internet
                </div>
                <div @click="addStringToMarkdownArea('IMAGE')" class="grey-rounded-small-button">
                  add image (ext url)
                </div>
                <div @click="addStringToMarkdownArea('MP3')" class="grey-rounded-small-button">
                  add mp3 (ext url)
                </div>
                <div @click="addStringToMarkdownArea('MAP')" class="grey-rounded-small-button">
                  add map
                </div>
                <div @click="addStringToMarkdownArea('YOUTUBE')" class="grey-rounded-small-button">
                  add youtube
                </div>
                <div @click="addStringToMarkdownArea('IFRAME')" class="grey-rounded-small-button">
                  embed external website
                </div>
              </div>
            </div>
            <div v-if="type==='day'">
              <div
              class="select is-small"
              v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
              >
                <select v-model="localValue">
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </select>
              </div>
            </div>
            <div v-if="type==='time'">
              <input
                class="input is-small"
                v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
                type="text"
                id="timepicker"
                autocomplete="off"
                v-model="localValue"
              />
              <button
                class="button is-small is-danger is-outlined mt-1"
                v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large'}"
                @click="localValue = null"
              >
                Reset
              </button>
            </div>
            <div v-if="type==='date'">
              <input
                class="input is-small"
                v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
                type="text"
                id="datepicker"
                autocomplete="off"
                v-model="localValue"
              />
              <button
                class="button is-small is-danger is-outlined mt-1"
                v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large'}"
                @click="localValue = null"
              >
                Reset
              </button>
            </div>
            <!-- <button
              v-if="type==='boolean'"
              @click="toggleBoolean()"
              class="button is-small mb-1"
              v-bind:class="{ 'is-success': localValue, 'is-light': !localValue, 'is-medium': size === 'medium', 'is-large': size === 'large' }"
            >
              {{ localValue ? "Yes" : "No"}}
            </button> -->
            <div v-if="type==='dropdown'">
              <div class="select">
                <select v-model="localValue">
                  <option v-for="(selection, index) in selections" :key="index" :value="selection.value">{{ selection.key }}</option>
                </select>
              </div>
              <!-- <div class="buttons has-addons">
                <div v-for="(selection, index) in selections" :key="index">
                  <button
                    @click="selectSelection(index)"
                    class="button is-small"
                    v-bind:class="{ 'is-primary': localValue === selection, 'is-light': localValue !== selection, 'is-medium': size === 'medium', 'is-large': size === 'large'}"
                  >
                    {{ selection }}
                  </button>
                </div>
              </div> -->
            </div>
            <div v-if="type==='selection'">
              <div class="select">
                <select v-model="localValue">
                  <option v-for="(selection, index) in selections" :key="index" :value="selection.type">{{ `${selection.type.charAt(0).toUpperCase() + selection.type.slice(1)} | ${selection.desc}` }}</option>
                </select>
              </div>
              <!-- <div class="buttons has-addons">
                <div v-for="(selection, index) in selections" :key="index">
                  <button
                    @click="selectSelection(index)"
                    class="button is-small"
                    v-bind:class="{ 'is-primary': localValue === selection, 'is-light': localValue !== selection, 'is-medium': size === 'medium', 'is-large': size === 'large'}"
                  >
                    {{ selection }}
                  </button>
                </div>
              </div> -->
            </div>
            <div v-if="type==='location'">
              <div v-if="!userLocation" class="container has-text-centered">
                <a
                @click="getLocation()"
                class="button has-text-weight-semibold is-small"
                >
                  <i class="fas fa-street-view mr-2"></i>
                  Get current location
                </a>
              </div>
              <div id="map" class="map" v-bind:class="{'short-map': !clickedGetLocation}"></div>
            </div>
            <div v-if="type==='qrbarcode'">
              <div v-if="openCamera" :key="cameraKey" class="container mb-1">
                <StreamBarcodeReader
                  @decode="onQrbarcodeDecode"
                ></StreamBarcodeReader>
              </div>
              <div class="container">
                <input
                  class="input is-small has-text-centered"
                  v-bind:class="{'is-medium': size === 'medium', 'is-large': size === 'large'}"
                  :placeholder="placeholder"
                  type="text"
                  :value="localValue"
                  disabled
                >
              </div>
              <div class="container">
                <div @click="toggleCamera()"
                class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-2"
                >
                  <i class="fas fa-qrcode mr-2"></i>
                  {{ openCamera ? "Close camera" : "Open camera to scan" }}
                </div>
              </div>
            </div>
            <div v-if="type==='color'">
              <input
                v-if="localValue && ['color'].includes(type)"
                class="input"
                style="max-width: 100px;"
                v-bind:class="{'is-small': size === 'small', 'is-medium': size === 'medium', 'is-large': size === 'large', 'is-danger': errorMessage}"
                :placeholder="placeholder"
                :type="type"
                v-model="localValue"
                v-on:blur="checkForErrors()"
              />
              <button
                v-if="localValue"
                v-bind:class="{'is-small': size === 'small', 'is-medium': size === 'medium', 'is-large': size === 'large'}"
                class="button is-danger is-outlined ml-1"
                @click="localValue = null"
              >
                <i class="fas fa-times"></i>
              </button>
              <button
                v-else
                v-bind:class="{'is-small': size === 'small', 'is-medium': size === 'medium', 'is-large': size === 'large'}"
                class="button is-primary is-outlined"
                @click="localValue = '#000000'"
              >
                Select colour
              </button>
            </div>
            <!-- <i v-if="helperImage && !errorMessage && !inputErrorMessage" @click="openHelperImage()" class="fas fa-question-circle has-text-grey is-clickable ml-2 mt-2"></i> -->
          </div>
          <div v-if="unit" class="ml-2 mt-3">{{ unit }}</div>
        </div>
      </div>
      <div class="is-flex is-align-items-flex-end">
        <vue-qrcode v-if="showQr && value" :value="value" quality="1" />
        <QrBarcodeTimeSensitiveButton v-if="showQr && value" :qrValue="value" class="mb-3"/>
      </div>
    </div>
    <div
    class="modal"
    v-if="showFileSelector"
    v-bind:class="{'is-active': showFileSelector}"
    >
      <div @click="toggleFileSelector()" class="modal-background pointer"></div>
      <div class="modal-content px-6">
        <files-view-module
        v-on:selectFile="selectFile($event)"
        :openedInCreator="true"
        :formInputType="type"
        />
        <button @click="toggleFileSelector()" class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
    <div
    class="modal"
    v-if="showUnsplashView"
    v-bind:class="{'is-active': showUnsplashView}"
    >
      <div @click="toggleUnsplashView()" class="modal-background pointer"></div>
      <div class="modal-content px-6">
        <unsplash-view
        v-on:selectFile="selectFileFromInternet($event)"
        :formInputType="type"
        />
        <button @click="toggleUnsplashView()" class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
    <div
    class="modal"
    v-if="showTextGenView"
    v-bind:class="{'is-active': showTextGenView}"
    >
      <div @click="toggleTextGenView()" class="modal-background pointer"></div>
      <div class="modal-content px-6">
        <text-generator
        v-on:insert="insertTextGenText($event)"
        />
        <button @click="toggleTextGenView()" class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
    <div
    class="modal"
    v-if="showHelperImageModal"
    v-bind:class="{'is-active': showHelperImageModal}"
    >
      <div @click="toggleHelperImageModal()" class="modal-background is-clickable"></div>
      <div class="modal-content is-flex is-justify-content-center">
        <img class="helper-image-modifier" :src="helperImage">
        <button @click="toggleHelperImageModal()" class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
    <div
    class="modal"
    v-if="showVideoTutorialModal"
    v-bind:class="{'is-active': showVideoTutorialModal}"
    >
      <div @click="toggleVideoTutorialModal()" class="modal-background is-clickable"></div>
      <div class="modal-content is-flex is-justify-content-center">
        <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${videoTutorialYoutubeId}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <button @click="toggleVideoTutorialModal()" class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import flatpickr from 'flatpickr'
import L from 'leaflet'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import FilesViewModule from '@/components/FilesView'
import UnsplashView from '@/components/UnsplashView.vue'
import TextGenerator from '@/components/TextGenerator.vue'
import MarkdownOutput from './MarkdownOutput'
import VueQrcode from 'vue-qrcode'
import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/vue-editor'
import QrBarcodeTimeSensitiveButton from '@/components/QrBarcodeTimeSensitiveButton.vue'

export default {
  name: 'FormInput',
  props: ['title', 'helptext', 'type', 'value', 'selections', 'size', 'errorMessage', 'mandatory', 'min', 'max', 'helperImage', 'hideLabel', 'showQr', 'videoTutorialYoutubeId', 'unit'],
  components: {
    StreamBarcodeReader,
    FilesViewModule,
    UnsplashView,
    TextGenerator,
    MarkdownOutput,
    VueQrcode,
    editor: Editor,
    QrBarcodeTimeSensitiveButton
  },
  data () {
    return {
      datetime: null,
      showFileSelector: false,
      showUnsplashView: false,
      showTextGenView: false,
      markdownAreaRef: null,
      map: null,
      cameraKey: 0,
      openCamera: false,
      clickedGetLocation: true,
      inputErrorMessage: null,
      showHelperImageModal: false,
      showVideoTutorialModal: false,
      markdownEditorRefresher: 1,
      markdownEditorOptions: {
        minHeight: '200px',
        hideModeSwitch: true,
        toolbarItems: [
          ['heading', 'bold', 'italic'],
          ['hr'],
          ['link']
        ]
      }
    }
  },
  methods: {
    refreshMarkdownRefresher () {
      this.markdownEditorRefresher += 1
    },
    onEditorChange () {
      this.localValue = this.$refs.markdownEditor.invoke('getMarkdown')
    },
    openHelperImage () {
      this.showHelperImageModal = true
    },
    checkForErrors () {
      if (
        this.mandatory &&
        ['text', 'markdown', 'image-url'].includes(this.type) &&
        (!this.value || this.value.length === 0)) {
        this.inputErrorMessage = 'Field is mandatory.'
        return
      }
      if (
        this.mandatory &&
        ['number', 'integer'].includes(this.type) &&
        this.value === null) {
        this.inputErrorMessage = 'Field is mandatory.'
        return
      }
      if (this.value !== null && (this.min || this.max)) {
        if (['text', 'markdown'].includes(this.type)) {
          if (this.min && (this.value.length < this.min)) {
            this.inputErrorMessage = `Minimum character length is ${this.min}`
            return
          } else if (this.max && this.value.length > this.max) {
            this.inputErrorMessage = `Maximum character length is ${this.max}`
            return
          }
        }
        if (['number', 'integer'].includes(this.type)) {
          if (this.min && this.value < this.min) {
            this.inputErrorMessage = `Minimum is ${this.min}`
            return
          } else if (this.max && this.value > this.max) {
            this.inputErrorMessage = `Maximum is ${this.max}`
            return
          }
        }
      }
      this.inputErrorMessage = null
    },
    toggleBoolean () {
      this.localValue = !this.localValue
    },
    selectSelection (index) {
      this.localValue = this.selections[index]
    },
    addStringToMarkdownArea (templateName) {
      const templates = {
        LINK: '[Click here](REPLACE_THIS_WITH_FULL_URL_WITH_HTTPS://)',
        IMAGE: '![ ](REPLACE_THIS_WITH_YOUR_IMAGE_URL)',
        MP3: 'MP3[REPLACE_THIS_WITH_MP3_URL]MP3',
        YOUTUBE: 'YT[VIDEO_ID]YT',
        IFRAME: '<iframe width="100%" height="600" src="REPLACE_THIS_WITH_FULL_URL_WITH_HTTPS://"></iframe>',
        MAP: 'MAP[REPLACE_THIS_WITH_FULL_ADDRESS]MAP'
      }
      this.localValue = (this.localValue ? this.localValue : '') + (this.localValue ? '\n\n' : '') + templates[templateName]
      this.refreshMarkdownRefresher()
      this.$buefy.toast.open({
        message: 'Placeholder added to BOTTOM of text. Replace the text between the [brackets].',
        type: 'is-primary',
        queue: false
      })
      // const cursorPosition = this.markdownAreaRef.selectionStart
      // if (this.localValue === null) {
      //   this.localValue = templates[templateName]
      // } else {
      //   this.localValue = [this.localValue.slice(0, cursorPosition), templates[templateName], this.localValue.slice(cursorPosition)].join('')
      // }
    },
    toggleHelperImageModal () {
      this.showHelperImageModal = !this.showHelperImageModal
    },
    toggleVideoTutorialModal () {
      this.showVideoTutorialModal = !this.showVideoTutorialModal
    },
    toggleFileSelector () {
      this.showFileSelector = !this.showFileSelector
    },
    toggleTextGenView () {
      this.showTextGenView = !this.showTextGenView
    },
    toggleUnsplashView () {
      this.showUnsplashView = !this.showUnsplashView
    },
    clearText () {
      this.localValue = null
    },
    selectFile (fileMetadata) {
      let textToInsert = ''
      if (!['image-url', 'mp3-url'].includes(this.type)) {
        if (fileMetadata.metadata.contentType.includes('image')) {
          textToInsert = `![${fileMetadata.filename}](${fileMetadata.url})`
        } else if (fileMetadata.metadata.contentType.includes('audio')) {
          textToInsert = `MP3[${fileMetadata.url}]MP3`
        } else if (fileMetadata.metadata.contentType.includes('video')) {
          textToInsert = `VIDEO[${fileMetadata.url}]VIDEO`
        } else {
          textToInsert = `PDF[${fileMetadata.url}]PDF`
        }
        if (this.type !== 'markdown' && this.localValue) {
          const cursorPosition = this.markdownAreaRef.selectionStart
          this.localValue = [this.localValue.slice(0, cursorPosition), textToInsert, this.localValue.slice(cursorPosition)].join('')
        } else {
          this.localValue = (this.localValue ? this.localValue : '') + (this.localValue ? '\n\n' : '') + textToInsert
          if (this.type === 'markdown') {
            this.refreshMarkdownRefresher()
          }
        }
      } else {
        this.localValue = fileMetadata.url
      }
      this.showFileSelector = false
    },
    selectFileFromInternet (imageData) {
      if (!['image-url', 'mp3-url'].includes(this.type)) {
        let textToInsert = ''
        textToInsert = `![ ](${imageData.fullLink})`
        this.localValue = (this.localValue ? this.localValue : '') + (this.localValue ? '\n\n' : '') + textToInsert
        if (this.type === 'markdown') {
          this.refreshMarkdownRefresher()
        }
      } else {
        this.localValue = imageData.fullLink
      }
      this.showUnsplashView = false
    },
    insertTextGenText (text) {
      this.localValue += '\n\n' + text
      this.refreshMarkdownRefresher()
      this.showTextGenView = false
    },
    getLocation () {
      const now = new Date()
      if (
        !this.$store.state.userLocation || (
          this.$store.state.userLocation && (now.getTime() - this.$store.state.userLocationExpiryTimestamp.getTime()) / 1000 > 60
        )
      ) {
        this.$store.dispatch('getUserLocationAction')
      }
      this.clickedGetLocation = true
    },
    initMap () {
      const location = [this.$store.state.userLocation.lat, this.$store.state.userLocation.lon]
      if (this.$store.state.userLocation) {
        this.map = L.map('map').setView(location, 35)
        this.tileLayer = L.tileLayer(
          'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=OYQRCyzroGxySflITX5444WUUWwvcUjp'
        )
        // eslint-disable-next-line new-cap
        const DefaultIcon = new L.icon({
          iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon.png'),
          iconSize: [25, 40],
          iconAnchor: [12.5, 40]
        })
        const marker = L.marker(location, {
          icon: DefaultIcon,
          draggable: true
        }).addTo(this.map)
        this.tileLayer.addTo(this.map)
        marker.on('dragend', () => {
          this.localValue = {
            lat: marker.getLatLng().lat,
            lon: marker.getLatLng().lng
          }
        })
      }
    },
    distanceBetweenTwoGeoCoordinatesInMeters (lat1, lon1, lat2, lon2) {
      if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0
      } else {
        const radlat1 = Math.PI * lat1 / 180
        const radlat2 = Math.PI * lat2 / 180
        const theta = lon1 - lon2
        const radtheta = Math.PI * theta / 180
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344
        return dist * 1000
      }
    },
    toggleCamera () {
      this.openCamera = !this.openCamera
      this.cameraKey += 1
      this.$forceUpdate()
    },
    onQrbarcodeDecode (result) {
      if (this.openCamera) {
        this.localValue = result
      }
      this.openCamera = false
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        if (value !== this.localValue) {
          if (this.type === 'integer' || this.type === 'day') {
            if (!value) {
              value = null
            } else {
              value = parseInt(value)
            }
          } else if (this.type === 'number') {
            if (value === '') {
              value = null
            } else {
              value = Number(value)
            }
          } else if (this.type === 'date') {
            if (value === '') {
              value = null
            }
          } else if (this.type === 'markdown') {
            if (value === '') {
              value = null
            }
          } else if (this.type === 'text' || this.type === 'qrbarcode') {
            if (value === '') {
              value = null
            }
          } else if (this.type === 'image-url') {
            if (value === '') {
              value = null
            }
          } else if (this.type === 'mp3-url') {
            if (value === '') {
              value = null
            }
          }
          this.$emit('update', value)
        }
        this.checkForErrors()
      }
    },
    placeholder () {
      const placeholders = {
        text: 'Text',
        markdown: 'Markdown',
        number: 'Number',
        integer: 'Whole numbers (0, 1, 2...)',
        qrbarcode: 'Scanned value will appear here',
        'image-url': 'Paste external URL or select from files',
        'mp3-url': 'Paste external URL or select from files'
      }
      return placeholders[this.type]
    },
    userLocation () {
      return this.$store.state.userLocation
    }
  },
  mounted () {
    if (this.type === 'time') {
      flatpickr('#timepicker', {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i'
      })
    }
    if (this.type === 'date') {
      flatpickr('#datepicker', {
        enableTime: false,
        noCalendar: false,
        dateFormat: 'Y-m-d'
      })
    }
    if (this.type === 'markdown') {
      this.markdownAreaRef = this.$refs.markdown
    }
    if (this.type === 'location') {
      const now = new Date()
      if (
        !(
          !this.$store.state.userLocation || (
            this.$store.state.userLocation && (now.getTime() - this.$store.state.userLocationExpiryTimestamp.getTime()) / 1000 > 60
          )
        )
      ) {
        this.initMap()
      } else {
        this.clickedGetLocation = false
      }
    }
  },
  watch: {
    userLocation (newUserLocation, oldUserLocation) {
      if (this.type === 'location' && !oldUserLocation && newUserLocation) {
        this.initMap()
        this.clickedGetLocation = true
      }
    }
  }
}
</script>

<style>
.map {
  height: 150px;
}

.short-map {
  height: 1px !important;
}

.textarea-fill-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.markdown-level-modifier {
  width: 50%;
}

.markdown-preview-border {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(218, 218, 218);
  border-radius: 3px;
}

.error-message-modifier {
  max-width: 186px;
}

.helper-image-modifier {
  height: 80%
}

.has-short-input-max-width {
  min-width: 150px;
  max-width: 150px;
}

.tab-item {
  display: none !important;
}
</style>
