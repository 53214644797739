<template>
  <div>
    <button @click="showModal = true" class="button is-small is-primary is-rounded is-outlined">
      Use secure, time-sensitive QR code
    </button>
    <div v-if="showModal" class="modal is-active">
      <div class="modal-background" @click="showModal = false"></div>
      <div class="modal-content custom-modal-content">
        <div class="box has-text-centered">
          <p class="mb-1">
            Use a more secure, time-sensitive QR code that prevents players from taking a snapshot of the QR code and distributing it.
          </p>
          <p class="mb-2">
            The link below opens up a QR code that refreshes every minute. Players scan the QR code instead to complete this task.
          </p>
          <p class="notification is-warning has-background-warning py-1 mb-1">
            WARNING: Changing the value of the answer will change the URL.
          </p>
          <a :href="qrLink" @click.prevent="copyToClipboard" class="button is-link is-light mt-4">
            {{ qrLink }}
          </a>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="showModal = false"></button>
    </div>
  </div>
</template>

<script>
import { createHash } from 'crypto'

export default {
  name: 'QrBarcodeTimeSensitiveButton',
  props: ['qrValue'],
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    qrLink () {
      console.log('qrValue', this.qrValue)
      const hash = createHash('md5').update(this.qrValue).digest('hex')
      const shortHash = hash.slice(0, 3) + hash.slice(-3)
      const currentDomain = document.location.origin
      return `${currentDomain}/qr/${shortHash}`
    }
  },
  methods: {
    async copyToClipboard () {
      await navigator.clipboard.writeText(this.qrLink)
      this.$buefy.toast.open({
        message: 'Link copied!',
        type: 'is-primary',
        queue: false
      })
    }
  }
}
</script>

<style scoped>
.custom-modal-content {
  max-width: 500px;
  margin: auto;
}
</style>
